/*.CalendarDay__blocked_calendar {*/
/*  @apply !bg-[#e4e7e7] cursor-not-allowed !border-[#e4e7e7] dark:!bg-[#4b5563] dark:!border-[#4b5563] line-through;*/
/*}*/
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@200;800&display=swap");
body {
  font-family: "Playfair Display", serif !important;
}
/*----------------------->>>>>>>>>>>>>-----------------*/
:invalid {
  color: rgba(156, 163, 175, 1);
}
.lang__icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.header__top {
  padding: 6px 0;
}
.toggle__design {
  margin: 0 20px !important;
}
.contact__email__bg {
}
p.text-justify.detail__intro {
  line-height: 2rem;
}

.CalendarMonthGrid {
  transform: translateX(0px) !important;
  width: 805px !important;
}
.CalendarMonthGrid_1 {
  transform: translateX(0px) !important;
  width: 805px !important;
}
.CalendarMonthGrid__horizontal {
  transform: translateX(0px) !important;
  width: 805px !important;
}
.CalendarMonthGrid__horizontal_2 {
  transform: translateX(0px) !important;
  width: 805px !important;
}
.textBlack {
  color: #000000 !important;
  font-weight: 500 !important;
}
@media (max-width: 576px) {
  /* .slider-image-item {
    flex: 1 1 auto !important;
    position: relative !important;
    width: 579px !important;
    height: 200px !important;
  } */

  .about___image {
    height: 250px !important;
    object-fit: fill !important;
  }
  .home__intro,
  .location__intro {
    grid-template-columns: repeat(1, 1fr);
  }
  .room__list {
    grid-template-columns: repeat(1, 1fr);
  }

  .slider_image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .item {
    margin-bottom: 44px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .hero__title {
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 21px 0px;
    line-height: 32px;
  }
  .hero__sub {
    display: none;
  }

  .hero__info {
    position: absolute;
    bottom: 50px;

    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%) !important;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.2rem;
    width: 100%;
  }
  .detail__intro > p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .left__intro,
  .right__intro {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 50px 0px;
  }
  .right__intro {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.52),
            rgba(0, 0, 0, 0.73)
    );
    width: 100%;
    height: 337px;
    background-size: cover;
    color: white;
    padding: 20px;
    position: relative;
  }

  .title__intro {
    /* font-size: 3rem; */
    line-height: 58px;
    padding-bottom: 30px;
  }
  .left__intro {
    padding: 0 1rem;
  }

  img.room__image {
    width: 100%;
    object-fit: cover;
    transition-duration: 0.5s;
    height: 333px;
  }
  img.room__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }

  .room__llist__area__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .area__title {
    width: 50%;
    /* font-size: 2.9rem; */
    line-height: 64px;
    text-transform: uppercase;
    padding-right: 40px;
  }
  .area__title__info {
    width: 50%;
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }

  .p__50 {
    padding: 50px 0 !important;
  }
  .p__100 {
    padding: 80px 0 !important;
  }
  .room__info {
    font-size: 0.9rem;
    margin-top: 16px;
  }
  .room__info p {
    text-align: center;
    padding: 0 10px;
  }
  .room__type {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  /*.room__type::after {*/
  /*  position: absolute;*/
  /*  content: "";*/
  /*  left: 0px;*/
  /*  top: 30px;*/
  /*  width: 50px;*/
  /*  height: 1px;*/
  /*  background-color: #b2a8a8;*/
  /*}*/

  img.inter__image {
    width: 100%;
    height: 320px;
    /*object-fit: cover;*/
    /*transition-duration: .5s;*/
  }
  img.inter__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }
  .item__international {
    position: relative;
    margin-bottom: 20px;
  }

  .inter__info h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 11px 0;
  }
  img.map__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .hero__slider {
    position: relative;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 600px) {
  .about___image {
    height: 500px !important;
    object-fit: fill;
    background-position: center center;
  }
  .home__intro,
  .location__intro {
    grid-template-columns: repeat(1, 1fr);
  }
  .room__list {
    grid-template-columns: repeat(1, 1fr);
  }

  .hero__info {
    position: absolute;
    bottom: 50px;

    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%) !important;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.2rem;
    width: 100%;
  }
  .hero__title {
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 21px 0px;
    line-height: 32px;
  }
  .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }

  .home__intro,
  .location__intro {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .detail__intro > p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .left__intro,
  .right__intro {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 50px 0px;
  }
  .right__intro {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.52),
            rgba(0, 0, 0, 0.73)
    );
    width: 100%;
    height: 337px;
    background-size: cover;
    color: white;
    padding: 20px;
    position: relative;
  }

  .title__intro {
    /* font-size: 3rem; */
    line-height: 58px;
    padding-bottom: 30px;
  }

  .left__intro {
    padding: 0 1rem;
  }
  .item {
    margin-bottom: 44px;
    margin-left: 5px;
    margin-right: 5px;
  }

  img.room__image {
    width: 100%;
    object-fit: cover;
    transition-duration: 0.5s;
    height: 185px;
  }
  img.room__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }

  .room__llist__area__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .area__title {
    width: 50%;
    /* font-size: 2.9rem; */
    line-height: 64px;
    text-transform: uppercase;
    padding-right: 40px;
  }
  .area__title__info {
    width: 50%;
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }

  .p__50 {
    padding: 50px 0 !important;
  }
  .p__100 {
    padding: 80px 0 !important;
  }
  .room__info {
    font-size: 0.9rem;
    margin-top: 16px;
  }
  .room__info p {
    text-align: center;
    padding: 0 10px;
  }
  .room__type {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  /*.room__type::after {*/
  /*  position: absolute;*/
  /*  content: "";*/
  /*  left: 0px;*/
  /*  top: 30px;*/
  /*  width: 50px;*/
  /*  height: 1px;*/
  /*  background-color: #b2a8a8;*/
  /*}*/

  .international__gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  img.inter__image {
    width: 100%;
    height: 320px;
    /*object-fit: cover;*/
    /*transition-duration: .5s;*/
  }
  img.inter__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }
  .item__international {
    position: relative;
  }
  .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: center;
    background: rgba(101, 86, 86, 0.36);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }

  .inter__info h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 11px 0;
  }
  img.map__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .slider_image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .hero__slider {
    position: relative;
  }
  [dir="ltr"] .faq__dir {
    text-align: left;
  }

  [dir="rtl"] .faq__dir {
    text-align: right;
  }
  [dir="ltr"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }
  [dir="rtl"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }

  [dir="ltr"] .hero__title {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }
  [dir="rtl"] .hero__title {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 768px) {
  .home__intro,
  .location__intro {
    grid-template-columns: repeat(1, 1fr);
  }
  .room__list {
    grid-template-columns: repeat(1, 1fr);
  }

  .slider_image {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  .item {
    margin-bottom: 44px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .hero__info {
    position: absolute;
    bottom: 50px;

    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%) !important;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.2rem;
    width: 100%;
  }
  [dir="ltr"] .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
  }
  [dir="rtl"] .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
    text-align: right;
  }
  [dir="rtl"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right !important;
  }
  [dir="ltr"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: left;
  }
  [dir="ltr"] .faq__dir {
    text-align: left;
  }

  [dir="rtl"] .faq__dir {
    text-align: right;
  }

  .detail__intro > p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .left__intro,
  .right__intro {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 50px 0px;
  }
  .right__intro {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.52),
            rgba(0, 0, 0, 0.73)
    );
    width: 100%;
    height: 337px;
    background-size: cover;
    color: white;
    padding: 20px;
    position: relative;
  }

  .title__intro {
    /* font-size: 3rem; */
    line-height: 58px;
    padding-bottom: 30px;
  }
  .left__intro {
    padding-right: 40px;
  }
  img.room__image {
    width: 100%;
    object-fit: cover;
    transition-duration: 0.5s;
    height: 150px;
  }
  img.room__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }

  .room__llist__area__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .area__title {
    width: 50%;
    /* font-size: 2.9rem; */
    line-height: 64px;
    text-transform: uppercase;
    padding-right: 40px;
  }
  .area__title__info {
    width: 50%;
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }

  .p__50 {
    padding: 50px 0 !important;
  }
  .p__100 {
    padding: 80px 0 !important;
  }
  .room__info {
    font-size: 0.9rem;
    margin-top: 16px;
  }
  .room__info p {
    text-align: center;
    padding: 0 10px;
  }
  [dir="ltr"] .room__type {
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  [dir="rtl"] .room__type {
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: right;
  }
  /*.room__type::after {*/
  /*  position: absolute;*/
  /*  content: "";*/
  /*  left: 0px;*/
  /*  top: 30px;*/
  /*  width: 50px;*/
  /*  height: 1px;*/
  /*  background-color: #b2a8a8;*/
  /*}*/

  .international__gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  img.inter__image {
    width: 100%;
    height: 320px;
    /*object-fit: cover;*/
    /*transition-duration: .5s;*/
  }
  img.inter__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }
  .item__international {
    position: relative;
  }
  [dir="ltr"].inter__info {
    position: absolute;
    bottom: 17px;
    text-align: center;
    background: rgba(101, 86, 86, 0.36);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="rtl"].inter__info {
    position: absolute;
    bottom: 17px;
    text-align: right;
    background: rgba(101, 86, 86, 0.36);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }

  .inter__info h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 11px 0;
  }
  img.map__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .slider_image {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
  .hero__slider {
    position: relative;
  }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 889px) {
  .home__intro,
  .location__intro {
    grid-template-columns: repeat(1, 1fr);
  }
  .room__list {
    grid-template-columns: repeat(1, 1fr);
  }

  .slider_image {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  .item {
    margin-bottom: 44px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .hero__info {
    position: absolute;
    bottom: 50px;

    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%) !important;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.2rem;
    width: 100%;
  }
  .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
  }
  .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: left;
  }

  .detail__intro > p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .left__intro,
  .right__intro {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 50px 0px;
  }
  .right__intro {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.52),
            rgba(0, 0, 0, 0.73)
    );
    width: 100%;
    height: 337px;
    background-size: cover;
    color: white;
    padding: 20px;
    position: relative;
  }

  .title__intro {
    /* font-size: 3rem; */
    line-height: 58px;
    padding-bottom: 30px;
  }
  .left__intro {
    padding-right: 40px;
  }
  img.room__image {
    width: 100%;
    object-fit: cover;
    transition-duration: 0.5s;
    height: 175px;
  }
  img.room__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }

  .room__llist__area__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .area__title {
    width: 50%;
    /* font-size: 2.9rem; */
    line-height: 64px;
    text-transform: uppercase;
    padding-right: 40px;
  }
  .area__title__info {
    width: 50%;
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }

  .p__50 {
    padding: 50px 0 !important;
  }
  .p__100 {
    padding: 80px 0 !important;
  }
  .room__info {
    font-size: 0.9rem;
    margin-top: 16px;
  }
  .room__info p {
    text-align: left;
    /*padding-left: 87px;*/
  }
  .room__type {
    font-size: 1.4rem !important;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  /*.room__type::after {*/
  /*  position: absolute;*/
  /*  content: "";*/
  /*  left: 0px;*/
  /*  top: 30px;*/
  /*  width: 50px;*/
  /*  height: 1px;*/
  /*  background-color: #b2a8a8;*/
  /*}*/

  .international__gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  img.inter__image {
    width: 100%;
    height: 320px;
    /*object-fit: cover;*/
    /*transition-duration: .5s;*/
  }
  img.inter__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }
  .item__international {
    position: relative;
  }
  [dir="ltr"] .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: center;
    background: rgba(101, 86, 86, 0.36);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="rtl"] .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: right;
    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="ltr"] .faq__dir {
    text-align: left;
  }

  [dir="rtl"] .faq__dir {
    text-align: right;
  }

  [dir="ltr"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }
  [dir="rtl"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }

  [dir="ltr"] .hero__title {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }
  [dir="rtl"] .hero__title {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }

  [dir="ltr"] .room__type {
    font-size: 1.4rem !important;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  [dir="rtl"] .room__type {
    font-size: 1.4rem !important;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: right;
  }
  .inter__info h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 11px 0;
  }
  img.map__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .slider_image {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
  .hero__slider {
    position: relative;
  }
}

@media (min-width: 1200px) {
  .home__intro,
  .location__intro {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .detail__intro > p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .left__intro,
  .right__intro {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 50px 0px;
  }
  .right__intro {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.52),
            rgba(0, 0, 0, 0.73)
    );
    width: 100%;
    height: 337px;
    background-size: cover;
    color: white;
    padding: 20px;
    position: relative;
  }

  .title__intro {
    /* font-size: 3rem; */
    line-height: 58px;
    padding-bottom: 30px;
  }
  .left__intro {
    padding-right: 40px;
  }
  img.room__image {
    width: 450px;
    object-fit: cover;
    transition-duration: 0.5s;
    height: 210px;
  }
  img.room__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }

  .room__llist__area__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .area__title {
    width: 50%;
    /* font-size: 2.9rem; */
    line-height: 64px;
    text-transform: uppercase;
    padding-right: 40px;
  }
  .area__title__info {
    width: 50%;
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .room__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .p__50 {
    padding: 50px 0 !important;
  }
  .p__100 {
    padding: 80px 0 !important;
  }
  .room__info {
    font-size: 0.9rem;
    margin-top: 16px;
  }
  .room__info p {
    text-align: left;
    /*padding-left: 87px;*/
  }

  .room__type::after {
    position: absolute;
    content: "";
    left: 0px;
    top: 30px;
    width: 50px;
    height: 1px;
    background-color: #b2a8a8;
  }

  .international__gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  img.inter__image {
    width: 100%;
    height: 320px;
  }
  img.inter__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }
  .item__international {
    position: relative;
  }
  [dir="ltr"] .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: center;
    background: rgba(101, 86, 86, 0.36);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    min-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="rtl"] .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: right;
    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    min-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }

  [dir="ltr"] .faq__dir {
    text-align: left;
  }

  [dir="rtl"] .faq__dir {
    text-align: right;
  }
  [dir="ltr"] .space__lang {
    padding-right: 30px;
  }
  [dir="rtl"] .space__lang {
    padding-left: 30px;
    padding-right: 0px !important;
  }
  [dir="ltr"] .room__type {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  [dir="rtl"] .room__type {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  [dir="ltr"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }
  [dir="rtl"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }
  .inter__info h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 11px 0;
  }
  img.map__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .slider_image {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
  .hero__slider {
    position: relative;
  }

  .hero__info {
    position: absolute;
    bottom: 146px;

    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    /* left: 5%; */
    /* transform: translate(-50%); */
    padding: 10px 20px;
    width: 582px !important;
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="ltr"] .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
  }
  [dir="rtl"] .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
    text-align: right;
  }
  [dir="ltr"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
  }
  [dir="rtl"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
  .info__des {
    display: grid;
    max-width: 70%;
    text-align: justify;
  }
  .home__intro,
  .location__intro {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    align-items: center;
  }
  .detail__intro > p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }

  .left__intro,
  .right__intro {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 50px 0px;
  }
  .right__intro {
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.52),
            rgba(0, 0, 0, 0.73)
    );
    /*url("https://www.arabianbusiness.com/cloud/2021/09/14/LrjuY2Yq-Kuwait-37.jpg");*/
    width: 100%;
    height: 337px;
    background-size: cover;
    color: white;
    padding: 20px;
    position: relative;
  }

  .title__intro {
    /* font-size: 3rem; */
    line-height: 58px;
    padding-bottom: 30px;
  }
  .left__intro {
    padding-right: 40px;
  }
  img.room__image {
    width: 450px;
    object-fit: cover;
    transition-duration: 0.5s;
    height: 280px;
  }
  img.room__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }

  .room__llist__area__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .area__title {
    width: 50%;
    /* font-size: 2.9rem; */
    line-height: 64px;
    text-transform: uppercase;
    padding-right: 40px;
  }
  .area__title__info {
    width: 50%;
    font-size: 1rem;
    font-weight: 100;
    line-height: 30px;
  }
  .room__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .p__50 {
    padding: 50px 0 !important;
  }
  .p__100 {
    padding: 80px 0 !important;
  }
  .room__info {
    font-size: 0.9rem;
    margin-top: 16px;
  }
  .room__info p {
    text-align: left;
    /*padding-left: 87px;*/
  }

  .room__type::after {
    position: absolute;
    content: "";
    left: 0px;
    top: 30px;
    width: 50px;
    height: 1px;
    background-color: #b2a8a8;
  }
  .international__gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  img.inter__image {
    width: 100%;
    height: 320px;
    /*object-fit: cover;*/
    /*transition-duration: .5s;*/
  }
  img.inter__image:hover {
    transform: scale(0.97);
    transition-duration: 0.5s;
  }
  .item__international {
    position: relative;
  }
  .item {
    margin-bottom: 44px;
    margin-left: 5px;
    margin-right: 5px;
  }
  [dir="ltr"] .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: center;
    background: rgba(22, 1, 1, 0.64);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
    border: 1px solid rgba(22, 1, 1, 0.3);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="rtl"] .inter__info {
    position: absolute;
    bottom: 17px;
    text-align: right;
    background: rgba(22, 1, 1, 0.64);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
    border: 1px solid rgba(22, 1, 1, 0.3);
    display: block;
    left: 50%;
    transform: translate(-50%);
    padding: 10px 9px;
    max-width: 450px;
    color: #fff;
    font-size: 1.2rem;
  }

  [dir="ltr"] .faq__dir {
    text-align: left;
  }

  [dir="rtl"] .faq__dir {
    text-align: right;
  }
  [dir="ltr"] .space__lang {
    padding-right: 30px;
  }
  [dir="rtl"] .space__lang {
    padding-left: 30px;
    padding-right: 0px !important;
  }
  [dir="ltr"] .room__type {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }
  [dir="rtl"] .room__type {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 18px 0;
    position: relative;
    text-align: center;
  }

  [dir="ltr"] .about__area h2{
    text-align: left;
  }

  [dir="rtl"] .about__area h2{
    text-align: right;
  }
  .inter__info h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 11px 0;
  }
  img.map__image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .slider_image {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
  .hero__slider {
    position: relative;
  }

  .hero__info {
    position: absolute;
    bottom: 146px;
    background: rgba(101, 86, 86, 0.36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    display: block;
    /*left: 5%;*/
    /* transform: translate(-50%); */
    padding: 10px 20px;
    /* width: 394px; */
    color: #fff;
    font-size: 1.2rem;
  }
  [dir="ltr"] .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
    text-align: left;
  }
  [dir="rtl"] .hero__title {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 21px 0px;
    text-align: right;
  }

  [dir="ltr"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: left;
  }
  [dir="rtl"] .hero__sub {
    font-size: 1.3rem;
    padding-bottom: 21px;
    text-align: right;
  }

  .listingSection__wrap {
    border-radius: 0 !important;
  }
}

/*animate button css*/

.animated-button {
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e0f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button1 {
  background: #fff;
  padding: 12px 40px;
  margin: 12px;
  display: inline-block;
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #000;
  font-size: 14px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #f7f5f5;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;*/
}

.animated-button1::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f7941f;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(rgba(43, 8, 8, 0.05)),
          to(#5a5656)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0.05), #5a5656);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(rgba(43, 8, 8, 0.05)),
          to(#5a5656)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0.05), #5a5656);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.gallery__grid-item1{
  grid-area: pic1;
}
.gallery__grid-item2{
  grid-area: pic2;
}
.gallery__grid-item3{
  grid-area: pic3;
}
.gallery__grid-item4{
  grid-area: pic4;
}
.gallery__grid-item5{
  grid-area: pic5;
}

.gallery__container{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "pic1 pic1 pic2 pic3"
                        "pic1 pic1 pic4 pic5";
}
.gallery__grid-item1{

}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(rgba(43, 8, 8, 0.05)),
          to(#5a5656)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0.05), #5a5656);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(rgba(43, 8, 8, 0.05)),
          to(#5a5656)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0.05), #5a5656);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
