@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed');

.wrapper{
    padding-left: 35%;
    padding-top: 20%;
}

/*.wrapper{*/
/*    padding: 20px;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%,-50%);*/
/*}*/
/**/

.pulse__btn__area{
    position: absolute;
}
.btn_wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.v__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.citizensVoice {
    position: relative;
    text-align: center;
    -webkit-animation: ripple 3s linear infinite;
    animation: rippleCv 3s linear infinite;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    font-size: 13px;
    display: flex;
    align-content: center;
    align-items: flex-end;
    background-color: #f7941f;
    color: #ffffff;
    z-index: 9;
    cursor: pointer;
}



.citizensVoice::before,
.citizensVoice::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    -webkit-animation: inherit;
    animation: inherit;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.citizensVoice::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.citizensVoice::before,
.citizensVoice::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    -webkit-animation: inherit;
    animation: inherit;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

@keyframes rippleCv {
    0% {
        box-shadow: 0 0 0 0.051rem rgba(247, 148, 31, 0.3);
    }

    20% {
        box-shadow: 0 0 0 0.7rem rgba(247, 148, 31, 0.2);
    }

    100% {
        box-shadow: 0 0 0 3rem rgba(247, 148, 31, 0);
    }
}